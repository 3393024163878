import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useBankIdGetSession } from '../api/queries';
import { BankIdBanner } from '../components/bank-id-banner';
import { NewLogo } from '../components/logo';

export const PollingView = () => {
  const navigate = useNavigate();

  const hideBanner = () => {
    navigate('/login');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { errorData, isLoading: sessionLoading } = useBankIdGetSession();

  return (
    <div className="w-full h-full flex flex-col justify-evenly">
      <NewLogo />
      <div className="p-[10%] w-full">
        <div className="bg-bgTertiary p-4 rounded-xl">
          <BankIdBanner loading={sessionLoading} onClose={hideBanner} data={errorData} />
        </div>
      </div>
      <div />
    </div>
  );
};
