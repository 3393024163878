import { CreditCardIcon, ExclamationCircleIcon, TicketIcon } from '@heroicons/react/24/outline';
import { Button, CircularProgress, Input, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptionsClientSecret, loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { classNames } from '../helpers';
import { useDebounce } from '../hooks/use-debounce';
import { TICKET_PURCHASE_LIMIT } from '../utils/constants';
import { plausible, trackEvent } from '../utils/track-events';
import { DashboardContext } from '../views';
import { CheckoutFormElements } from './checkout-form-elements';
import { Dialog } from './dialog';

interface Props {
  open: boolean;
  onClose: () => void;
}

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

const stripeTestPromise = loadStripe(
  import.meta.env.VITE_STRIPE_TEST_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

export const PaymentDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [tempNumberOfTickets, setTempNumberOfTickets] = useState(1);
  const queryClient = useQueryClient();

  const [cardEnabled, setCardEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const ticketMissmatch = tempNumberOfTickets !== numberOfTickets;

  const {
    paymentCode,
    setPaymentCode,
    paymentInitiated,
    setPaymentInitiated,
    paymentIntent,
    setPaymentIntent,
    setMessage,
    handlePaymentCode,
    setPaymentCodeError,
    paymentCodeError,
  } = useContext(DashboardContext);

  const navigate = useNavigate();

  const options: StripeElementsOptionsClientSecret = {
    clientSecret: paymentIntent?.paymentIntent?.client_secret || '',
    appearance: {
      theme: 'night',
    },
  };

  const handleSwish = async () => {
    setLoading(true);
    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data } = await api.paymentsControllerInitSwishForFacility({
        facilityId: facility.id,
        initPaymentDto: { numberOfTickets },
      });

      clearPaymentCode();

      const appUrl = `swish://paymentrequest?token=${data.swishToken}&callbackurl=${window.location.origin}/swish/status?statusCheckToken=${data.statusCheckToken}`;
      window.location.href = appUrl;
    } catch (error) {
      setMessage('Något gick fel');
      setLoading(false);
    }
  };

  useEffect(() => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      setPaymentCode(paymentCodeLocalStorage);
      handlePaymentCode(paymentCodeLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearPaymentCode = () => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      localStorage.removeItem('paymentCode');
    }
    setPaymentCode('');
  };

  const handleClose = () => {
    clearPaymentCode();
    onClose();
    setMessage('');
    setLoading(false);
    setPaymentInitiated(false);
    setPaymentIntent(undefined);
    setCardEnabled(false);
    setNumberOfTickets(1);
    setTempNumberOfTickets(1);
    navigate('/dashboard/paid');
  };

  useEffect(() => {
    if (open && !paymentCode) {
      const prefillPaymentCode = localStorage.getItem('prefillPaymentCode');
      if (prefillPaymentCode) {
        setPaymentCode(prefillPaymentCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleConfirm = async () => {
    setLoading(true);

    try {
      const { data } = await api.paymentsControllerConfirmPaymentIntent({
        paymentIntentId: paymentIntent?.paymentIntent?.id as string,
      });

      if (['succeeded', 'paid'].includes(data.status as unknown as string)) {
        queryClient.invalidateQueries(['qr-code']);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeNumberOfTickets(Number(event.target.value));
  };

  const handleButtonPress = (i: number) => {
    changeNumberOfTickets(i);
  };

  const handleTempNumberOfTickets = (i: number) => {
    let val = i;

    if (val > TICKET_PURCHASE_LIMIT) {
      val = TICKET_PURCHASE_LIMIT;
    }
    setTempNumberOfTickets((prev) => prev + val);
  };

  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);
  const debouncedHandleButtonPress = useDebounce(handleButtonPress, 1000);

  const changeNumberOfTickets = (i: number) => {
    let numberOftickets = i;
    if (numberOftickets <= 0) {
      setNumberOfTickets(1);
      setTempNumberOfTickets(1);
      return;
    }
    if (i > TICKET_PURCHASE_LIMIT) {
      numberOftickets = TICKET_PURCHASE_LIMIT;
    }
    setNumberOfTickets(numberOftickets);
    setTempNumberOfTickets(numberOftickets);
    setLoading(true);
    handlePaymentCode(paymentCode, numberOftickets).finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="rounded-lg p-8 flex flex-col gap-5 min-w-[224px]  ">
        {!paymentIntent && paymentInitiated ? (
          <div className="m-auto">
            <CircularProgress />
          </div>
        ) : paymentIntent && options.clientSecret ? (
          <>
            <Typography variant="h5" style={{ color: 'black' }}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              {paymentIntent.paymentIntent.metadata.facilityName}:{' '}
              {paymentIntent.paymentIntent.amount / 100} {paymentIntent.paymentIntent.currency}
            </Typography>
            <Typography style={{ color: 'black' }} textAlign={'center'}>
              Antal biljetter {numberOfTickets}
            </Typography>
            <div className="flex w-full flex-1">
              <div
                role="button"
                onClick={() => {
                  debouncedHandleButtonPress(tempNumberOfTickets - 1);
                  handleTempNumberOfTickets(-1);
                }}
                className="text-not-selectable cursor-pointer"
                style={{
                  pointerEvents: tempNumberOfTickets <= 1 ? 'none' : 'auto',
                  opacity: tempNumberOfTickets <= 1 ? 0.3 : 1,
                }}
              >
                <div className="h-10 w-10 border border-primary-main text-primary-main bg-[#00000066] grid place-content-center text-2xl">
                  &#45;
                </div>
              </div>
              <div className="flex justify-center items-center w-full flex-1 min-w-[96px]">
                <Input
                  type="number"
                  inputProps={{
                    color: 'black',
                    min: 0,
                    max: TICKET_PURCHASE_LIMIT,
                    style: { textAlign: 'center', fontSize: '24px' },
                  }}
                  className="input-hidearrows"
                  disabled={loading}
                  style={{
                    color: 'black',
                    width: '64px',
                  }}
                  value={tempNumberOfTickets}
                  onChange={(e) => {
                    debouncedHandleInputChange(e as ChangeEvent<HTMLInputElement>);
                    setTempNumberOfTickets(Number(e.target.value));
                  }}
                />
              </div>
              <div
                className="text-not-selectable cursor-pointer"
                role="button"
                onClick={() => {
                  debouncedHandleButtonPress(tempNumberOfTickets + 1);
                  handleTempNumberOfTickets(+1);
                }}
                style={{
                  pointerEvents: tempNumberOfTickets >= TICKET_PURCHASE_LIMIT ? 'none' : 'auto',
                  opacity: tempNumberOfTickets >= TICKET_PURCHASE_LIMIT ? 0.3 : 1,
                }}
              >
                <div className="h-10 w-10 border border-primary-main text-primary-main bg-[#00000066] grid place-content-center text-2xl">
                  &#43;
                </div>
              </div>
            </div>
            <div className="relative">
              {(ticketMissmatch || loading) && (
                <div className="absolute top-0 left-0 right-0 bottom-0 z-1 grid place-content-center">
                  <CircularProgress size={32} />
                </div>
              )}
              <div className="flex flex-col gap-5" style={{ opacity: ticketMissmatch ? 0.5 : 1 }}>
                <Button
                  size="large"
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    padding: '10px 20px',
                    fontSize: 16,
                    opacity: loading ? 0.5 : 1,
                  }}
                  variant="contained"
                  disabled={loading || ticketMissmatch}
                  startIcon={<img style={{ width: 24 }} src="/swish.png" />}
                  onClick={handleSwish}
                >
                  Betala med Swish
                </Button>
                {cardEnabled ? (
                  <>
                    {paymentIntent.isTest ? (
                      <Elements options={options} stripe={stripeTestPromise}>
                        <CheckoutFormElements
                          onConfirm={handleConfirm}
                          paymentIntent={paymentIntent.paymentIntent}
                        />
                      </Elements>
                    ) : (
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutFormElements
                          onConfirm={handleConfirm}
                          paymentIntent={paymentIntent.paymentIntent}
                        />
                      </Elements>
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    disabled={loading || ticketMissmatch}
                    fullWidth
                    style={{
                      backgroundColor: 'white',
                      border: '2px solid black',
                      color: 'black',
                      padding: '5px 10px',
                      fontSize: 16,
                    }}
                    endIcon={<CreditCardIcon />}
                    onClick={() => setCardEnabled(true)}
                  >
                    <span id="button-text">Betala med kort</span>
                    <CreditCardIcon aria-hidden="true" className="-mr-0.5 h-5 w-5 ml-5" />
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              style={{
                color: 'black',
              }}
            >
              {t('dashboard.enter_payment_code')}
            </Typography>
            <form
              className="flex flex-col gap-5"
              onSubmit={(e) => {
                e.preventDefault();
                plausible.trackPageview({ url: `${window.location.origin}/${paymentCode}` });
                trackEvent('manual-payment-code-entered', { posthogData: { paymentCode } });
                handlePaymentCode(paymentCode);
              }}
            >
              <div>
                <label
                  htmlFor="payment-code"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Payment code
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    autoFocus
                    onChange={(e) => {
                      if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                      setPaymentCode(e.target.value);
                    }}
                    defaultValue="adamwathan"
                    id="payment-code"
                    name="payment-code"
                    type="text"
                    value={paymentCode}
                    placeholder="club_kaskad..."
                    aria-invalid="true"
                    aria-describedby="payment-code-error"
                    className={classNames(
                      'pl-2 block w-full rounded-md border-0 py-1.5 pr-10  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                      paymentCodeError
                        ? 'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500'
                        : 'text-gray-900 ring-1 ring-inset ring-gray-300 placeholder-gray-300 focus:ring-gray-500',
                    )}
                  />
                  {paymentCodeError ? (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon aria-hidden="true" className="h-5 w-5 text-red-500" />
                    </div>
                  ) : (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <TicketIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    </div>
                  )}
                </div>
                {paymentCodeError && (
                  <p id="email-error" className="mt-2 text-sm text-red-600">
                    Not a valid code.
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="items-center gap-x-2 rounded-md bg-black px-3.5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 flex flex-row justify-between"
              >
                {t('dashboard.continue')}
                <CreditCardIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
              </button>
            </form>
          </>
        )}
      </div>
    </Dialog>
  );
};
