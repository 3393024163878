import { cn } from '../utils/css';

export const Spinner = ({ size = 'base' }: { size?: 'big' | 'base' | 'small' }) => {
  return (
    <div
      className={cn(
        size === 'base' && `size-8 border-4 `,
        size === 'small' && `size-4 border-2 `,
        size === 'big' && `size-10 border-4 `,
        'inline-block animate-spin rounded-full text-primary border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]',
      )}
      role="status"
    />
  );
};
