import { useEffect, useState } from 'react';

import AccountIcon from '@mui/icons-material/AccountCircle';
import ArticleIcon from '@mui/icons-material/Article';
import CakeIcon from '@mui/icons-material/Cake';
import CampaignIcon from '@mui/icons-material/Campaign';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Switch,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { useUpdateCurrentUser } from '../api/mutations';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';

export const ProfileView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateCurrentUser } = useUpdateCurrentUser();
  const { logout, user: currentUser } = useAuth();
  const date = new Date(currentUser?.user?.birthdate ?? '');
  const formatedDate = date.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handleUpdateCommunication = (allowCommunication: boolean) => {
    updateCurrentUser({ allowCommunication });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOptOut = () => {
    handleUpdateCommunication(false);
    setDialogOpen(false);
  };

  return (
    <>
      <div>
        <Container>
          <div className="flex justify-between mb-4 text-white mt-4">
            <div>
              <ArrowUturnLeftIcon className="h-6 w-6" onClick={() => navigate('/dashboard')} />
            </div>
            <h2>{t('appbar.profile')}</h2>
            <div></div>
          </div>
          <Box
            sx={{
              display: 'flex',
              mt: 2,
              mx: 1,
              p: 1,
              paddingRight: 0,
              borderRadius: 3,
              backgroundColor: 'white',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <ButtonBase
              sx={{
                display: 'flex',
              }}
              onClick={() => navigate('/profile/phone')}
              disabled
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon fontSize="medium" component={PhoneIcon} />

                  <Typography variant="body1" align="left">
                    {t('phone.phone')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
                  <Typography variant="body1" align="left" sx={{ color: 'rgba(0,0,0,0.8)' }}>
                    {currentUser?.user ? currentUser?.user?.phone : 'no phone'}
                  </Typography>
                </Box>
              </Box>
            </ButtonBase>
            <Divider sx={{ my: 1 }} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon fontSize="medium" component={CakeIcon} />

                <Typography variant="body1" align="left">
                  {t('completeprofile.dob')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" align="left" sx={{ color: 'rgba(0,0,0,0.8)' }}>
                  {currentUser?.user ? formatedDate : 'no date'}
                </Typography>
                <ChevronRightIcon sx={{ fontSize: 40, color: 'rgba(0,0,0,0.0)', marginLeft: -3 }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon fontSize="medium" component={AccountIcon} />

                <Typography variant="body1" align="left">
                  {t('completeprofile.gender')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant="body1"
                  align="left"
                  textTransform={'capitalize'}
                  sx={{ color: 'rgba(0,0,0,0.8)' }}
                >
                  {currentUser?.user?.gender && currentUser?.user?.gender}
                </Typography>
                <ChevronRightIcon sx={{ fontSize: 40, color: 'rgba(0,0,0,0.0)', marginLeft: -3 }} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 4,
              mx: 1,
              p: 1,
              paddingRight: 0,
              borderRadius: 3,
              backgroundColor: 'white',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <ButtonBase
              sx={{
                display: 'flex',
              }}
              onClick={() => {
                navigate('/terms');
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon fontSize="medium" component={ArticleIcon} />
                  <Typography variant="body1" align="left">
                    {t('profile.terms')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChevronRightIcon
                    sx={{
                      fontSize: 40,
                      color: 'rgba(0,0,0,1)',
                    }}
                  />
                </Box>
              </Box>
            </ButtonBase>
            <Divider sx={{ my: 1 }} />
            <ButtonBase
              sx={{
                display: 'flex',
              }}
              onClick={() => {
                navigate('/policy');
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon fontSize="medium" component={ArticleIcon} />
                  <Typography variant="body1" align="left">
                    {t('profile.policy')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChevronRightIcon
                    sx={{
                      fontSize: 40,
                      color: 'rgba(0,0,0,1)',
                    }}
                  />
                </Box>
              </Box>
            </ButtonBase>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 4,
              mx: 1,
              p: 1,
              paddingRight: 0,
              borderRadius: 3,
              backgroundColor: 'white',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <ButtonBase
              sx={{
                display: 'flex',
              }}
              onClick={() => {}}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon fontSize="medium" component={CampaignIcon} />
                  <Typography variant="body1" align="left">
                    {t('profile.communication')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Switch
                    checked={currentUser?.user.allowCommunication ?? false}
                    onChange={() => {
                      if (currentUser?.user.allowCommunication) {
                        setDialogOpen(true);
                      } else {
                        handleUpdateCommunication(true);
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </Box>
              </Box>
            </ButtonBase>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 2,
              px: 3,
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2" align="center" sx={{ color: 'white' }}>
              {t('profile.support')}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, px: 1 }}>
            <Button
              sx={{ flex: 1, py: 1, borderRadius: 4 }}
              variant="outlined"
              color="primary"
              onClick={logout}
            >
              {t('profile.logout')}
            </Button>
          </Box>
        </Container>
      </div>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            EasyJacket wont be able to send you any communication, even about forgotten items. Are
            you sure you want to opt out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleOptOut}>
            Opt-out
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
