export const TICKET_PURCHASE_LIMIT = 20;

export const COLORS = {
  primary: {
    main: 'rgb(15, 121, 227)',
    darker: 'rgb(9, 77, 156)',
    lighter: 'rgb(90, 162, 233)',
    background: 'rgb(26, 28, 33)',
    text: 'rgb(242, 242, 251)',
    border: 'rgb(88, 105, 126)',
  },
  secondary: {
    background: 'rgb(38, 42, 51)',
    text: 'rgb(212, 219, 234)',
    border: 'rgb(58, 63, 73)',
  },
  tertiary: {
    background: 'rgb(44, 49, 58)',
  },
  accent: {
    pink: 'rgb(204, 134, 247)',
    green: 'rgb(13, 203, 110)',
  },
  disabled: {
    text: 'rgb(112, 117, 125)',
  },
};
