import { useState } from 'react';
import Fab from '@mui/material/Fab';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import '../css/ScrollButton.css';

export function ScrollButton() {
  const [isVisible, setIsVisible] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Fab
      onClick={() => scrollToTop()}
      sx={{
        margin: 0,
        top: 'auto',
        right: 30,
        bottom: isVisible ? 30 : -100,
        left: 'auto',
        position: 'fixed',
        transition: 'all .3s ease',
        WebkitTransition: 'all .3s ease',
        MozTransition: 'all .3s ease',
        color: 'white',
      }}
      color="primary"
      aria-label="scroll-to-top"
    >
      <UpIcon />
    </Fab>
  );
}
