import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { ScrollButton } from '../components/scroll-button';

export const TermsView = () => {
  const markdown = `# Användarvillkor

1. Introduktion

Tack för att ni valt att använda EasyJacket, Sveriges smartaste, smidigaste och säkraste garderobsbiljett. I dessa användarvillkor (“Villkoren”) stadgas de juridiska skyldigheter samt rättigheter som en användare (”Användaren”) av EasyJacket AB:s tjänster, f.n. webbapplikation (“Hemsidan”), har. Genom Villkoren regleras även de åtgärder som EasyJacket AB (“EasyJacket”) kan vidta i den mån Användaren inte efterlever de skyldigheter som denne åtar sig genom användandet av Hemsidan.

Användarens rättigheter i förhållande till insamling av personuppgifter för Hemsidans funktionssätt finner i “Integritetspolicy”.

2. Upprättande av användare och godkännande av användarvillkor

Under tiden för nyttande av Hemsidan är det viktigt att Användaren är införstådd med de rättigheter och skyldigheter som reglerar användandet. Vid upprättandet av ett användarkonto (“Kontot”) genom Hemsidan samtycker Användaren till Villkoren och innehållet i detta avtal. I det fall Kontot raderas kan Användaren inte längre erhålla de tjänster som EasyJacket tillhandahåller..

3. Användandet av Hemsidan och garderobsbiljetten

Hemsidan tillhandahåller en garderobsbiljett som endast är avsedd att nyttjas i samband med frånlämnande av tillhörigheter i förhållande till en garderobsinrättning (“Garderoben”). Garderoben drivs av tredje part som t.ex. kan vara en restaurang, konsertlokal, mässhall etc. (”Garderobsinnehavaren”).

Användaren får enklast tillgång till Hemsidans tjänster genom en smartphone som medtas till Garderoben. Efter beställning förser Hemsidan Användaren med en garderobsbiljett i form av en elektronisk signatur (“QR-koden”) som avstäms av Garderobsinnehavaren. Garderobsinnehavaren nyttjar systemet i syfte att specificera på vilken plats i Garderoben en viss Användares tillhörigheter placeras.

Garderobsinnehavaren scannar QR-koden i samband med inlämning av tillhörigheter till Garderoben. Vid utlämning scannas QR-koden igen och tillhörigheterna utlämnas till Användaren. QR-koden skall uppvisas på det sätt som Hemsidan medger. Avbildningar av QR-koden som uppvisas utanför en aktiv uppkoppling mot Hemsidan är inte giltiga.

Om Användarens smartphone är ur funktion eller förkommit vid utlämnade, kan Användaren identifiera sig på annat sätt bl.a. genom körkort förutsatt att sättet medger en säker identifiering.

Garderobsinnehavaren är inte ansvarig att utlämna Användarens tillhörigheter om inte uppvisande av QR-koden sker mot avstämning i Garderobens system eller annan säker identifiering kan ske. Om Användaren inte kan uppvisa tillhörigheternas motsvarande QR-kod eller annan säker identifiering har Garderobsinnehavaren rätt att kvarhålla egendomen till dess att Användaren kan uppvisa erforderlig bevisning om att tillhörigheterna tillhör denne. Erforderlig bevisning utgörs av en från Användaren eller närstående person tydlig beskrivning av tillhörigheternas utformning, skick och det innehåll som omfattas av tillhörigheterna.

Garderobsinnehavaren fastställer hur länge Användaren får förvara tillhörigheter i Garderoben. Hemsidan är alltid aktiv men användning genom utlämning begränsas till de öppethållandetider som Garderobsinnehavaren fastställer. Om inte Användaren hämtar ut sina tillhörigheter i rätt tid kommer Garderobsinnehavaren hantera dessa som kvarglömda tillhörigheter. Anvisar Garderobsinnehavaren till viss tid och plats för avhämtning av kvarglömda tillhörigheter, skall Användaren infinna sig vid sådant ställe under den anvisade tidpunkten.

4. Betalning för användning av Garderoben

Hemsidan i sig kräver inte betalning av Användaren för att kunna nyttjas.

Garderobsinnehavaren beslutar om Användare ska erlägga ersättning för QR-koden och i sådant fall till vilket belopp. Om betalning ska erläggas tillhandahåller Hemsidan olika sätt för digital betalning av QR-koden. Betalning erläggs senast omedelbart före inlämning av kläder till Garderoben.

5. Easyjackets Ansvar för tillhörigheter

EasyJacket ansvarar inte för Användarens tillhörigheter under tiden dessa förvaras i Garderoben.

6. Garderobsinnehavarens ansvar för tillhörigheter

Garderobsinnehavaren ansvarar för inlämnade tillhörigheter enligt gällande rättsregler. Anspråk på ersättning för skada till följd av försummelse vid förvaring av tillhörigheter riktas därför av Användaren mot Garderobsinnehavaren.

7. Rätten att försälja kvarglömda tillhörigheter efter uppmaning om avhämtning

Tillhörigheter som kvarlämnats hos Garderobsinnehavaren får säljas enlig gällande regler. Försäljning kan ske under förutsättning att:

- Användaren har till de registrerade kontaktuppgifterna tillsänts SMS om att upphämta kvarglömda tillhörigheter, och att underlåtenhet att upphämta kvarglömda tillhörigheter kan resultera i att dessa försäljs efter tre (3) månaders tid från tillfället för Hemsidans nyttjande i förhållande till de kvarglömda tillhörigheterna.
- Tiden för upphämtning av de kvarglömda tillhörigheterna har löpt ut (3 månader).

Försäljning av kvarglömda tillhörigheter innebär att tillhörigheternas värde, efter avdrag för kostnader för försäljningen, återförs till Användaren.

Kvarglömda tillhörigheter vars värde uppenbarligen inte överstiger en hundradel av prisbasbeloppet (2 kap 6 – 7 § Socialförsäkringsbalken) och som kvarlämnats hos Garderobsinnehavaren i ett (1) år efter det tillfälle vid vilket Hemsidan upphört att nyttjas får försäljas utan anmaning till Användaren. Överskottet skall därefter utbetalas till Användaren. Kan inte Garderobsinnehavaren fastställa var Användaren kan anträffas, äger Garderobsinnehavaren rätten att tillgodogöra sig överskottet av försäljningen.

8. Avtalstid och uppsägning

Avtalets innehåll och bundenheten mellan EasyJacket och Användaren kvarstår under den tidsperiod som Användaren har ett registrerat Konto hos EasyJacket. Användaren äger rätten att avsluta sitt Konto genom Hemsidan. Efter att kontot avslutats kan Användare inte använda Hemsidans tjänster.

QR-koden äger giltighet från det tillfälle vid vilket Användaren mottager denna från Garderoben i utbyte mot inlämning av tillhörigheter, och upphör vid det tillfälle vid vilket Användaren mot avstämning av QR-koden i Garderoben mottager sina tillhörigheter.

9. Radera användarkonto

Användare som önskar radera Kontot och dess innehåll genomför sådan åtgärd genom Hemsidan. Önskar Användaren därutöver att begära radering av personuppgifter som lagrats med anledning av användandet skall detta meddelas EasyJacket. Meddelande om sådan begäran lämnas genom nyttjandet av den kontaktinformation som återfinns längst ned i detta avtal.

10. Skyldighet att inte MissbRuka hemsidan eller QR-koden

Genom att upprätta ett användarkonto på Hemsidan förbinder sig Användaren att inte vidta någon åtgärd som syftar till att störa Hemsidans funktion eller påverka dess säkerhet. Detta innefattar t.ex. försök att omvandla, förvränga eller komma åt innehåll bortom det innehåll som EasyJacket avsett att Användaren skall få tillgång till genom upprättandet av ett användarkonto.

Vidare förbinder sig Användaren att inte missbruka eller överlåta QR-kodens till tredje part.

Missbruk i något av dessa hänseenden medför att EasyJacket äger rätten att göra anspråk på ersättning för den skada som uppkommer genom förfarandet. Skada kan i detta avseende utgöras av att EasyJacket på grund av missbruk av Användaren inte kan upprätta samarbete med part som utan missbruket skulle ha kommit till stånd. Skada kan vidare utgöras av ersättningsanspråk från Användare avseende förlorade tillhörigheter till följd av förvrängt eller omvandlat innehåll i Hemsidan.

11. Hemsidans innehåll

\[Hemsidans innehåll är avsett för det ändamål som tydliggörs under punkt 3. Innehåll som annars publiceras av EasyJacket skall anses falla inom ramen för detta innehåll. Innehållet utformas av EasyJacket samt till denne anknutna tjänsteleverantörer och samarbetsparter. Användaren åtar sig att inte vidta någon åtgärd i syfte att extrahera (från Hemsidan exportera) det material som återfinns på Hemsidan i form av logotyper, marknadsföringsmaterial och liknande innehåll. Återfinns det vidare på Hemsidans innehåll eller material som tillhör tredje part, åtar sig Användaren att, på samma vis som avser EasyJackets material inte extrahera detta.\]

Hemsidan kan komma att nyttjas i samarbete med leverantör av tredjepartsapplikation (annat än QR-kod för Garderoben). Uppstår på Hemsidan en sådan tredjepartsapplikation är det fortsättningsvis detta avtal som reglerar användandet av Hemsidan. EasyJacket ansvarar inte för den avgift som tillhandahållaren av sådan tredjepartsapplikation eventuellt uppställer.

12. Easyjackets ansvar, Reklamation

Angående ansvar för tillhörigheter och försäljning av kvarglömda tillhörigheter, se punkt 5 - 7. Användare har inte ångerrätt enligt gällande lagregler eftersom tjänsten som EayJacket tillhandahåller som regel konsumeras direkt efter beställning/betalning. EasyJacket ansvarar i övrigt för sina tjänster i förhållande till Användare, primärt för QR-kodens funktion. Vill användaren reklamera EasyJackets tjänster sker skriftligen till EasyJacket via e-mail eller brev till enligt kontaktuppgifter i punkt 15.

13. Användandet av registrerade uppgifter

EasyJackets användning av uppgifter som Användaren lämnar vid registrering av konto beskrivs i den separata Integritetspolicyn.. I den mån Användaren önskar begränsa EasyJackets användning i detta avseende äger Användaren rätten att begära sådan begränsning, alternativt radering av personuppgifter, genom att meddela EasyJacket detta önskemål. För vidare information avseende de skyldigheter EasyJacket har gentemot Användaren, se Integritetspolicyn.

14. Övriga villkor

_Force majeure  
_Omständigheter utanför parts kontroll, som inte orsakats av parten eller annan för vilken parten svarar, och som inte heller med iakttagande av skälig aktsamhet kunnat undvikas, ska anses utgöra befrielsegrund (force majeure), om de hindrar avtalsförpliktelsernas fullgörande samt inte rimligen kunnat förutses av parten. Om befrielsegrund inträffar bär vardera parten själv risk och ansvar för eventuella merkostnader eller onödiga kostnader som parten ådrar sig till följd av befrielsegrunden.

_Ändring av avtalsinnehåll  
_Sker ändring i dessa användarvillkor meddelas Användaren vid tillfället för sådan ändring. Användaren äger vid sådant tillfälle rätt att radera sitt användarkonto om Användaren inte godtar ändringen.

_Fullständigt avtal  
_Innehållet i detta avtal, rubricerat Användarvillkor, är en fullständig reglering av de rättigheter och förpliktelser Användaren samt EasyJacket överenskommit. Inga muntliga avtal äger företräde i förhållande till detta innehåll och eventuell förändring av innehållet skall kommuniceras till Användaren på det vis som stadgas under punkt 14. I tillämpliga fall äger Användaren dock rätt att åberopa tvingande bestämmelser i konsumenträttsliga regler.

_Överlåtelse  
_EasyJacket äger rätten att överlåta Hemsidan och övrig verksamhet till tredje part. Vid överlåtelse följer att Användaren är bunden genom avtalet mot ny ägare på samma vis som om EasyJacket kvarstått som ägare. Rätt att radera användarkonto enligt punkt 9 kvarstår oförändrad.

15. Kontakta oss

Vid eventuella frågor avseende innehållet i detta avtal se kontaktinformation nedan.

Adam Andersson, <Adam@EasyJacket.se>

EasyJacket AB  
Org. nr. 559232-8081  
Momsnummer SE559232-808101  
C/o Venner Group AB,  
Skeppsbron 5,  
411 21 Göteborg  
Telefon XXX.`;

  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 overflow-auto">
      <div style={{ padding: 4, backgroundColor: 'white', paddingTop: 4 }}>
        <ReactMarkdown remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>
        <ScrollButton />
      </div>
    </div>
  );
};
