import React, { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { AuthUser } from '../generated';
import { useGetCurrentUser } from '../api/queries';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/browser';
import { ACCESS_TOKEN_KEY } from '../api';

interface AuthContextProps {
  user?: AuthUser;
  loading: boolean;
  isAuthenticated: boolean;
  logout: () => void;
  isInitialLoading: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  isAuthenticated: false,
  loading: true,
  logout: () => {},
  isInitialLoading: true,
});

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const {
    currentUser: user,
    userIsLoading,
    isAuthenticated,
    isInitialLoading,
  } = useGetCurrentUser();

  const [identified, setIdentified] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && (user?.user?.birthdate || user?.user?.phone) && !identified) {
      const id = user?.user?.phone || `${user?.user.birthdate}-${user.user.id.substring(0, 5)}`;
      posthog.identify(id, {
        phone: user.user.phone,
        birthDate: user.user.birthdate,
        id: user.user.id,
      });
      Sentry.setUser({
        id: user.user.id,
        phone: user.user.phone,
      });
      setIdentified(true);
    }
  }, [isAuthenticated, user, identified]);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    queryClient.clear();
    navigate('/login');
  }, [navigate, queryClient]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        logout,
        loading: userIsLoading,
        isInitialLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
export { AuthContext, AuthProvider };
