import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ACCESS_TOKEN_KEY, api } from '../api';
import { LoadingView } from './loading-view';
import { useTickets } from '../api/queries';

export const SwishStatusView = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [initiated, setInitiated] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const tickets = useTickets();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const statusCheckToken = searchParams.get('statusCheckToken');

  const queryClient = useQueryClient();

  useEffect(() => {
    const checkSwishPayment = async (statusCheckToken: string, attempt = 0) => {
      if (attempt > 10) {
        return;
      }

      const { data: swishStatusObject } = await api.paymentsControllerSwishStatus({
        token: statusCheckToken,
      });

      if (swishStatusObject.status === 'CREATED') {
        timeoutRef.current = setTimeout(
          () => checkSwishPayment(statusCheckToken, attempt + 1),
          1000,
        );
      } else if (swishStatusObject.accessToken) {
        const { data: user } = await api.usersControllerFindMe({
          headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        });
        if (user) {
          localStorage.setItem(ACCESS_TOKEN_KEY, swishStatusObject.accessToken);
          queryClient.invalidateQueries(['currentUser']);

          await tickets.refetch();

          setTimeout(() => {
            navigate('/dashboard/paid');
          }, 500);
        }
      } else {
        setErrorMessage('Något gick fel');
        clearTimeout(timeoutRef.current);
      }
    };

    if (statusCheckToken && !initiated) {
      setInitiated(true);
      checkSwishPayment(statusCheckToken);
    } else {
      setErrorMessage('Något gick fel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorComponent = (
    <>
      <div className="min-h-screen flex flex-col justify-center items-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-100">
              Something went wrong 😢
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-300">{errorMessage}</p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            onClick={() => navigate('/dashboard')}
            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back
          </button>
        </div>
      </div>
    </>
  );

  return <>{errorMessage ? errorComponent : <LoadingView />}</>;
};
