import './app.css';

import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { ProtectedRoute } from './components';
import { AuthProvider, useAuth } from './contexts/auth-context';
import { plausible, trackEvent } from './utils/track-events';
import {
  DashboardView,
  LoadingView,
  LoginView,
  PhoneLoginView,
  PolicyView,
  PollingView,
  ProfileView,
  TermsView,
} from './views';
import { ChatView } from './views/chat-view';
import { ReceiptView } from './views/receipt-view';
import { ReceiptsView } from './views/receipts-view';
import { SmsLinkView } from './views/sms-link';
import { SwishStatusView } from './views/swish-status-view';
import { NetworkAlert } from './components/network-alert';

export const signedInPaths = [
  '/dashboard',
  '/dashboard/checked-in',
  '/dashboard/paid',
  '/profile',
  '/receipts',
  '/terms',
  '/policy',
  '/sms-link',
  '/chat',
];

const Layout = () => {
  const { isAuthenticated, isInitialLoading } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const paymentCode = searchParams.get('paymentCode');
  if (paymentCode) {
    localStorage.setItem('paymentCode', paymentCode);
    plausible.trackPageview({ url: `${window.location.origin}/${paymentCode}` });
    trackEvent('payment-qr-code-scanned', {
      posthogData: {
        paymentCode,
      },
    });
    setSearchParams({});
  }

  const { pathname } = useLocation();

  if (isInitialLoading) return <LoadingView />;

  if (
    !isInitialLoading &&
    isAuthenticated &&
    !signedInPaths.includes(pathname) &&
    !pathname.includes('receipts') &&
    !pathname.includes('swish')
  ) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0">
      <NetworkAlert>
        <Outlet />;
      </NetworkAlert>
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '',
    element: (
      <AuthProvider>
        <Layout />
      </AuthProvider>
    ),
    children: [
      {
        path: 'login',
        element: <LoginView />,
      },
      {
        path: 'polling',
        element: <PollingView />,
      },
      {
        path: 'sms-link',
        element: <SmsLinkView />,
      },
      {
        path: 'phone',
        element: <PhoneLoginView />,
      },
      {
        path: 'terms',
        element: <TermsView />,
      },
      {
        path: 'policy',
        element: <PolicyView />,
      },
      {
        path: 'docs/terms',
        element: <TermsView />,
      },
      {
        path: 'docs/policy',
        element: <PolicyView />,
      },
      {
        path: 'chat',
        element: <ChatView />,
      },
      {
        path: 'swish/status',
        element: <SwishStatusView />,
      },
      {
        path: '',
        element: <ProtectedRoute />,
        children: [
          {
            path: 'dashboard',
            element: <DashboardView />,
          },
          {
            path: 'dashboard/checked-in',
            element: <DashboardView />,
          },
          {
            path: 'dashboard/paid',
            element: <DashboardView />,
          },
          {
            path: 'profile',
            element: <ProfileView />,
          },
          {
            path: 'receipts',
            element: <ReceiptsView />,
          },
          {
            path: 'receipts/:id',
            element: <ReceiptView />,
          },
        ],
      },
    ],
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
