import React from 'react';
import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

const getOnLineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true;

export const NetworkAlert = ({ children }: Props) => {
  const [status, setStatus] = React.useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  React.useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <div className="w-full h-full">
      <motion.div
        initial={{ y: -50 }}
        animate={{ y: !status ? 0 : -50 }}
        transition={{ duration: 0.5 }}
        className={`fixed top-0 left-0 w-full h-8 flex items-center justify-center text-white bg-red-500`}
      >
        No network connection
      </motion.div>
      {children}
    </div>
  );
};
