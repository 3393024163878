import { io } from 'socket.io-client';

export const baseUrl = import.meta.env.VITE_API_BASE_URL;

export const socket = io(baseUrl, {
  autoConnect: false,
  transports: ['websocket'],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});
