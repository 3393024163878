import Link from '@mui/material/Link';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CloudsImage from '../assets/clouds.jpg';

import { ArrowTopRightOnSquareIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';
import BankIDLogo from '../assets/BankID_logo.png';
import Logo from '../assets/easyjacket_logo.svg';
import { useUserConsent } from '../hooks/use-consent';
import { useInitBankId } from '../hooks/use-init-bank-id';

export const LoginView = () => {
  const { t, i18n } = useTranslation();
  const { consent, updateConsent } = useUserConsent();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { init } = useInitBankId();

  const handleLogin = (type: 'bankid' | 'phone') => {
    if (!consent) {
      flashConsentButton();
    } else {
      type === 'bankid' ? init() : navigate('/phone');
    }
  };

  const flashConsentButton = () => {
    const button: HTMLElement | null = document.querySelector('#consent-button');
    if (button) {
      const label: HTMLElement | null = button.querySelector('label');
      if (label) label.style.color = '#fc5555';

      button.style.transform = 'scale(1.15)';
      setTimeout(() => {
        button.style.transform = 'scale(1)';
        setTimeout(() => {
          button.style.transform = 'scale(1.15)';
        }, 200);
      }, 200);
    }
  };

  return (
    <div
      className="w-full h-full flex justify-between flex-col text-white"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)), url(${CloudsImage})`,
      }}
    >
      <div className="justify-end flex p-2">
        <a
          href="https://info.easyjacket.se"
          target="_blank"
          rel="noreferrer"
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-full  px-2.5 py-1.5 text-sm font-semibold text-white border-2 border-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          {t('appbar.business')}
          <ArrowTopRightOnSquareIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
        </a>
      </div>
      <img src={Logo} className="w-[70%] self-center" />
      <div className="flex gap-8 flex-col">
        <div className="flex gap-2 justify-center">
          <button
            type="button"
            className="rounded-full bg-white px-3.5 py-2 text-sm font-medium text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            onClick={() => {
              if (i18n.language === 'sv') {
                i18n.changeLanguage('en');
              } else {
                i18n.changeLanguage('sv');
              }
            }}
          >
            {i18n.language === 'sv' ? 'Svenska' : 'English'}
          </button>
        </div>
        <div className="flex-row flex justify-center">
          <button
            onClick={(event) => {
              event.preventDefault();
              handleLogin('bankid');
            }}
            type="button"
            className="font-medium h-[64px] relative w-[80%] rounded-full bg-white px-4 py-2.5 text-lg text-black shadow-sm hover:bg-white/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            <div className="absolute top-0 left-2 bottom-0 flex items-center justify-center">
              <img src={BankIDLogo} className="h-[80%]" />
            </div>
            {t('login.button.BankID')}
          </button>
        </div>
        <div className="flex-row flex justify-center">
          <button
            type="button"
            className="w-[80%] relative rounded-full bg-black/20 px-4 py-2.5 text-sm font-medium shadow-sm hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white border text-white"
            onClick={(event) => {
              event.preventDefault();
              handleLogin('phone');
            }}
          >
            <div className="absolute top-0 left-4 bottom-0 flex items-center justify-center">
              <DevicePhoneMobileIcon width={18} height={18} color="white" />
            </div>
            {t('login.button.phone')}
          </button>
        </div>
        <div className="flex-row flex justify-center px-[15%]">
          <div
            className="relative flex items-start"
            id="consent-button"
            style={{
              transitionDuration: '0.2s',
              transitionProperty: 'all',
              boxShadow: 'none',
              ...(consent ? { transform: 'scale(1)' } : {}),
            }}
          >
            <div className="flex h-6 items-center">
              <input
                checked={!!consent}
                onChange={(e) => updateConsent(e.target.checked)}
                id="comments"
                name="comments"
                type="checkbox"
                aria-describedby="comments-description"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="comments"
                className="font-small text-white"
                style={{
                  color: consent ? '#45b34c' : '#e27272',
                }}
              >
                {`${t('login.checkbox.consent')} ${t('login.consent.5')} `}
                <Link
                  fontWeight="bold"
                  underline="always"
                  color="rgba(255,255,255,1)"
                  href="/policy"
                >
                  {t('login.consent.4')}
                </Link>
                {` & `}
                <Link
                  fontWeight="bold"
                  underline="always"
                  color="rgba(255,255,255,1)"
                  href="/terms"
                >
                  {t('login.consent.2')}
                </Link>
                .
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="text-xs flex justify-center flex-col items-center mb-2">
        <p>EasyJacket AB - info@easyjacket.se</p>
        <p>© 2024 EasyJacket AB - Org. 559232-8081</p>
      </div>
    </div>
  );
};
