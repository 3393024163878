import { api } from '../api';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { InitResponse, SessionResponse } from '../generated';

export const useInitBankId = () => {
  const navigate = useNavigate();

  const openOnThisDevice = async (data: InitResponse) => {
    const isSamsung = /Samsung/i.test(navigator.userAgent);

    const redirectPath = `/polling?sessionId=${data.sessionId}`;

    let bankidString;
    let sessionResponse: SessionResponse | undefined;

    try {
      const { data: sessionData } = await api.authControllerGetSession({
        id: data.sessionId ?? '',
      });
      sessionResponse = sessionData;
    } catch (error) {
      console.error(error);
    }

    if (isSamsung || sessionResponse?.error) {
      bankidString = `bankid:///?autostarttoken=${data.autoStartToken}&redirect=${
        isSamsung ? 'null' : window.location.origin + redirectPath
      }`;
    } else {
      bankidString = `https://app.bankid.com/?autostarttoken=${data.autoStartToken}&redirect=${window.location.origin}${redirectPath}`;
    }

    navigate(redirectPath);
    window.location.href = bankidString;
  };
  const {
    isLoading: initLoading,
    mutate,
    data,
  } = useMutation(
    ['bank-id-init'],
    async () => {
      const { data } = await api.authControllerInitUserSignIn({});
      return data;
    },
    {
      onSuccess: openOnThisDevice,
    },
  );

  return {
    data,
    openOnThisDevice,
    initLoading,
    init: mutate,
    isLoading: initLoading,
  };
};
