export function getZoneNameColor(
  letter?: string,
  customColor?: boolean,
  customColorValue?: string,
) {
  if (customColor && customColorValue) {
    return customColorValue;
  }

  switch (letter) {
    case 'A':
      return '#B53E3E'; // Red
    case 'B':
      return '#2160DB'; // Blue
    case 'C':
      return '#DC6914'; // Orange
    case 'D':
      return '#B53EA2'; // Purple
    case 'E':
      return '#9A9640'; // Olive
    case 'F':
      return '#3E6EB5'; // Navy
    case 'G':
      return '#14DC69'; // Green
    case 'H':
      return '#A23EB5'; // Magenta
    case 'I':
      return '#409A96'; // Teal
    case 'J':
      return '#DB6021'; // Dark Orange
    case 'K':
      return '#4CA14C'; // Greenish
    case 'L':
      return '#DB3E21'; // Dark Red
    case 'M':
      return '#2169DB'; // Bright Blue
    case 'N':
      return '#DB2169'; // Pink
    case 'O':
      return '#DB9A21'; // Gold
    case 'P':
      return '#2172DB'; // Light Blue
    case 'Q':
      return '#DB21A1'; // Fuchsia
    case 'R':
      return '#3E93B5'; // Sky Blue
    case 'S':
      return '#2161DB'; // Medium Blue
    case 'T':
      return '#DB8C21'; // Dark Gold
    case 'U':
      return '#2185DB'; // Deep Blue
    case 'V':
      return '#DB2161'; // Rose
    case 'W':
      return '#215BDB'; // Cerulean
    case 'ARCHIVE':
      return '#DB216F'; // Light Magenta
    case 'BAG':
      return '#6E21DB'; // Indigo
    default:
      return '#000000'; // Black
  }
}

export const getContrastColor = (hexColor: string) => {
  // Convert hex color to RGB
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Calculate the luminance of the color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;

  // Return black for light colors and white for dark colors
  return luminance > 186 ? '#000000' : '#FFFFFF';
};
