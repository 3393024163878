import axios, { AxiosRequestConfig } from 'axios';

import { DefaultApi } from '../generated';

export const axiosBaseUrl = import.meta.env.VITE_API_BASE_URL;

export const axiosConfig: AxiosRequestConfig = {
  baseURL: axiosBaseUrl,
};

export const axiosBackendClient = axios.create(axiosConfig);

export const ACCESS_TOKEN_KEY = 'accessToken';

export const createAuthHeader = (accessToken: string) => {
  return `Bearer ${accessToken}`;
};

axiosBackendClient.interceptors.response.use(
  function (response) {
    const newAccessToken = response.data.accessToken;
    const prevAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (newAccessToken && prevAccessToken !== newAccessToken) {
      localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
      axiosBackendClient.defaults.headers.common.Authorization = createAuthHeader(newAccessToken);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosBackendClient.interceptors.request.use((config) => {
  config.headers.Authorization = createAuthHeader(localStorage.getItem(ACCESS_TOKEN_KEY) || '');
  config.headers['ngrok-skip-browser-warning'] = true;
  return config;
});

export const api = new DefaultApi(
  {
    basePath: axiosBaseUrl,
    isJsonMime: () => false,
  },
  undefined,
  axiosBackendClient,
);
