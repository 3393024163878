import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../client';

type Props = {
  phone: string;
  otp: string;
};

export const usePhoneSignIn = () => {
  const queryClient = useQueryClient();
  const { isLoading, data, mutate } = useMutation({
    mutationFn: ({ phone, otp }: Props) =>
      api.authControllerSignInUser({
        data: {
          phone,
          otp,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['currentUser']);
    },
  });

  return { data: data?.data, isLoading, mutate };
};
