import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    mode: 'dark',
    action: {
      disabled: 'rgba(255,255,255,0.3)',
      disabledBackground: 'rgba(255,255,255,0.2)',
    },
    primary: {
      main: '#1DA2DC',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h5: {
      fontWeight: 500,
      color: '#fff',
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});
