import Plausible, { EventOptions, PlausibleOptions } from 'plausible-tracker';
import posthog, { Properties } from 'posthog-js';

export const plausible = Plausible({
  domain: 'www.easyjacket.com',
  apiHost: 'https://analytics.vennergroup.com',
});

interface extraData {
  posthogData?: Properties | null;
  plausibleOptions?: EventOptions;
  plausibleData?: PlausibleOptions;
}

export const trackEvent = (event: string, opt?: extraData) => {
  if (!import.meta.env.PROD) return;

  try {
    plausible.trackEvent(event, opt?.plausibleOptions, opt?.plausibleData);
    posthog.capture(event, opt?.posthogData);
  } catch (error) {
    console.error(error);
  }
};
