import { Button, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const ChatView = () => {
  const [chatUnavailable, setChatUnavailable] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    new ZammadChat({
      background: '#1DA2DC',
      fontSize: '12px',
      chatId: 1,
      flat: true,
      show: true,
      debug: true,
      target: $('#chat-root'),
    });

    let count = 0;
    const interval = setInterval(() => {
      count++;
      if (count > 10) {
        clearInterval(interval);
        setChatUnavailable(true);
      }
      if (ref.current?.style.display === 'none') {
        clearInterval(interval);
        setChatUnavailable(true);
      } else {
        setChatUnavailable(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {chatUnavailable && (
          <Typography variant="h6" style={{ marginBottom: 16, color: 'white' }}>
            Chat is currently unavailable
          </Typography>
        )}
        <Button ref={ref} variant="contained" size="large" className="open-zammad-chat">
          Chat with us
        </Button>
      </div>
      <div id="chat-root"></div>
    </>
  );
};
