import { AuthUser, UpdateUserDto } from '../generated';

import { useState } from 'react';
import { useUpdateCurrentUser } from '../api/mutations/use-update-user';
import { useAuth } from '../contexts/auth-context';

export const useUserForms = () => {
  const { user: currentUser } = useAuth();

  const [userFields, setUserFields] = useState<Partial<AuthUser>>({
    ...currentUser,
  });
  const { updateCurrentUser } = useUpdateCurrentUser();

  const handleSubmit = ({ dto }: { dto: Partial<UpdateUserDto> }) => {
    updateCurrentUser({ ...dto });
  };

  return { userFields, setUserFields, handleSubmit };
};
