import { useState } from 'react';
import { usePhoneInit, usePhoneSignIn } from '../api/mutations';

export const usePhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false);

  const { isLoading: initLoading, mutate: init } = usePhoneInit();
  const { mutate: signIn, isLoading: signInloading } = usePhoneSignIn();

  const handleInit = (phone: string) => {
    setPhoneNumber(phone);
    init(phone, {
      onSuccess: () => setShowCodeInput(true),
    });
  };

  const handleSignIn = (code: string) => {
    if (!phoneNumber) return;
    signIn({ phone: phoneNumber, otp: code });
  };

  const handleRetry = () => {
    setShowCodeInput(false);
  };

  return {
    showCodeInput,
    handleSignIn,
    handleRetry,
    phoneNumber,
    handleInit,
    isLoading: initLoading || signInloading,
  };
};
