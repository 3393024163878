import { api } from '../client';
import { useQuery } from '@tanstack/react-query';

export const useGetCurrentUser = () => {
  const query = useQuery(['currentUser'], () => api.usersControllerFindMe(), {
    retry: false,
  });

  const currentUser = query.data?.data;
  const isAuthenticated = !!currentUser;

  return { ...query, currentUser, userIsLoading: query.isLoading, isAuthenticated };
};
