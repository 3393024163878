import { api } from '../client';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../contexts/auth-context';

export const useTickets = (userId?: string) => {
  const { user } = useAuth();

  const query = useQuery({
    queryKey: [`qr-code`, userId ?? user?.user.id],
    queryFn: async () => api.usersControllerGetQrCodes(),
    enabled: !!userId || !!user?.user.id,
    refetchInterval: 10000,
  });

  const qrCodes = query.data?.data;
  return { ...query, ...qrCodes };
};
