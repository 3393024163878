import { Backdrop, Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWindowSize } from '../hooks/use-window-size';
import { useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, api, createAuthHeader } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useGetCurrentUser } from '../api/queries';

export const SmsLinkView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { height } = useWindowSize();
  const [params] = useSearchParams();
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();
  const { isError } = useGetCurrentUser();

  const token = params.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setMessage('Invalid token');
        setLoading(false);
        return;
      }

      try {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        const { data } = await api.usersControllerFindMe({
          headers: {
            Authorization: createAuthHeader(token),
          },
        });
        if (data?.accessToken) {
          await queryClient.invalidateQueries(['currentUser']);
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error(error);
        setMessage('Link has expired or is invalid...');
        setLoading(false);
      }
    };

    verifyToken();
  }, [token, navigate, queryClient]);

  return (
    <Box
      height={height}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        backgroundColor: 'rgba(0,0,0,1)',
      }}
    >
      {loading || !isError ? (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
            }}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h4" component="h1" gutterBottom color="white">
              {message || 'Loading...'}
            </Typography>
          </div>
        </Backdrop>
      ) : (
        <Container maxWidth="xs" sx={{ alignItems: 'center', mt: 10 }}>
          <>
            <Typography variant="h4" component="h1" gutterBottom color="white">
              {message || 'Something went wrong. Please try again.'}
            </Typography>
            <Button variant="contained" onClick={() => (window.location.href = '/')}>
              Go to Home
            </Button>
          </>
        </Container>
      )}
    </Box>
  );
};
