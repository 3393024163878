import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { ScrollButton } from '../components/scroll-button';

export const PolicyView = () => {
  const markdown = `# EasyJackets integritetspolicy

2024-07-30

## 1. Introduktion

Tack för att ni valt att använda EasyJacket, Sveriges smartaste, smidigaste och säkraste garderobsbiljett.

Vi strävar efter att användandet av vår tjänst f.n. webbapplikation (“Hemsidan”) ska vara så smidigt som möjligt och att ert besök inte störs av problem med garderobsbiljetter. Därför har vi upprättat denna integritetspolicy (“Policyn”) som tillgodoser behovet av insikt i hur vi arbetar och hanterar personuppgifter.

## 2. Om denna policy

Denna Policy beskriver hur EasyJacket AB (“EasyJacket”) behandlar era (“Användarens”) personuppgifter. Den omfattar samtliga tjänsteutbud som är hänförliga till EasyJackets Hemsida. Skulle EasyJacket tillhandahålla en ny tjänst kommer det inför lanseringen av sådan tjänst meddelas huruvida en förändring av dessa integritetsbestämmelser är vid handen. Meddelas ingen sådan förändring är det denna Policy som gäller även för de utökade tjänsterna.

Hemsidan och övriga tjänster erbjuds Användare av EasyJacket i samarbete med EasyJackets lokala kund (”Kunden”) vilket kan vara en restaurang, konsertlokal, mässhall eller annan verksamhet dit allmänheten kan ges tillträde och som har behov av en garderobstjänst eller övriga tjänster.

Syftet med denna integritetspolicy är att:

- Säkerställa att ni har insikt i vilka personuppgifter vi insamlar, samt ändamålet och hanteringen av insamlade personuppgifter
- Tydliggöra hur insamlandet av personuppgifter tillhandahåller EasyJacket nödvändig information för Hemsidans funktionssätt samt vidareutvecklingen av de tjänster EasyJacket har som målsättning att erbjuda er som Användare.
- Förklara vilka rättigheter ni har som tillhandahållare av personuppgifter, samt
- Redogöra för hur vår hantering av era personuppgifter säkerställs genom lagring och säkerhet i förhållande till sådan lagring av personuppgifter som sker avseende personuppgifter.

Om ni motsätter er EasyJackets hantering av era personuppgifter har ni alltid möjlighet att avstå från användandet av Hemsidan.

## 3. Ändamålen med behandlingen av Personuppgifter

EasyJacket samlar in och behandlar personuppgifter för följande ändamål:

1. Tillhandahålla vår tjänst, dvs. utställa en personlig garderobsbiljett som via digitalhantering upptas av vårt centrala garderobssystem.
2. Utveckla Hemsidan samt tillhörande tjänster.
3. Marknadsföra våra tjänster.
4. Tillhandahålla uppgifter om våra Användare till Kund för att Kunden ska kunna upprätthålla trygghet, ordning och säkerhet i sin verksamhet.
5. Tillhandahålla uppgifter om våra Användare till Kund för att Kunden ska kunna marknadsföra sin verksamhet och produkter till Användare.
6. Personuppgifter som är föremål för hantering

EasyJacket inhämtar och lagrar personuppgifter som är behövliga, väsentliga och förenliga med ändamålen. I samband med användningen av EasyJackets tjänst kan personuppgifter inhämtas och lagras. De uppgifter som kan komma att behandlas är följande:

- 1. Namn
  2. Personnummer
  3. Kön
  4. Betalningsinformation (uppgifter om bankkonto, betalkort, Swish etc.)
  5. Mailadress
  6. Mobilnummer samt
  7. Tidpunkten för erhållande av garderobsbiljett samt tidpunkten för avstämning mot biljetten vid utlämning av ert avsedda garderobsinnehåll.

Personuppgifter samlas in från följande källor: Insamling av personuppgifter sker med hjälp av mobilt BankID, från banker, betalkortsföretag, Swish samt genom att Användare själv lämnar uppgifterna.

Användaren lämnar personuppgifter frivilligt. Om Användaren inte lämnar efterfrågad information kan det medföra att Användaren inte erhåller EasyJackets tjänster.

Personuppgifter samlas in, struktureras, bearbetas, lagras och gallras. Automatiserat beslutsfattande och profilering (automatisk behandling och utvärdering av personuppgifter) av Användare förekommer inte.

## 4. Rättslig grund för hantering av personuppgifter

EasyJackets behandling av personuppgifter sker på följande rättsliga grunder:

- EasyJacket behandlar personuppgifter (i, ii, iv, v, vi, vii) för att fullgöra ingångna avtal, dvs att tillhandahålla sin tjänst enligt (a) ovan och erhålla betalning.
- EasyJacket behandlar personuppgifter (i - vii) utifrån sitt berättigade intresse av att kunna marknadsföra EasyJackets produkter till sina Kund (angående direktreklamspärr – se nedan) och i analyssyfte för att utveckla Hemsidan och övriga tjänster
- EasyJacket behandlar personuppgifter (i, ii, iii, v, vi, vii) i samarbete med Kund utifrån Kundens berättigade intresse av att kunna upprätthålla trygghet, ordning och säkerhet i sin verksamhet samt av att kunna marknadsföra sin verksamhet och sina produkter (angående direktreklamspärr – se nedan).

## 5. Hantering av personuppgifter i syfte att utveckla vår tjänst

EasyJacket använder personuppgifter i syfte att vidareutveckla Hemsidan, se punkt 3 (b). Vid utformandet av nya tjänster kan EasyJacket komma att nyttja informationen i syfte att använda denna som underlag för skapandet av dessa tjänster. Användaren har samma rättigheter enligt punkt 11 “Våra Skyldigheter” att vidta åtgärd i syfte att begränsa eller radera de uppgifter som nyttjas av oss vid detta tillfälle.

## 6. Hantering av personuppgifter i marknadsföringssyfte, direktreklamspärr

EasyJacket använder insamlad information i syfte att marknadsföra Hemsidan och för övriga tjänster som utvecklas av EasyJacket samt för att Kunder ska kunna marknadsföra sina tjänster. Se punkt 3 (c) och (e). Personuppgifter kan nyttjas genom att vi har möjlighet att rikta informationen till er som Användare. Rättigheten att begära radering eller begränsning av sådant nyttjande återfinns under punkt 11 “Våra Skyldigheter ”.

Användaren kan begära att EasyJacket inför direktreklamspärr. Då erhåller Användaren inte reklaminformation direkt från EasyJacket eller Kunder.

## 7. Mottagare av insamlade personuppgifter

Nedan listas den typ av mottagare som genom avtal med EasyJacket äger möjlighet att mottaga insamlade personuppgifter:

- Kunder som genom avtal erhåller insamlade personuppgifter i syfte att genom uppgifterna rikta erbjudande eller marknadsföring. Kunder finns i Sverige och ev. även i annat EU-land
- Tredje part i form av myndighet som genom föreläggande äger rätten att begära ut insamlade personuppgifter. Myndigheter finns i Sverige och i undantagsfall annat EU-land.
- Tredje part i form av avtalspart som genom avtalet äger rätten att disponera över insamlade personuppgifter i syfte att genomföra marknadsundersökning eller liknande statistisk undersökning avseende användandet av Hemsidan. Dessa mottagare finns i Sverige och i undantagsfall annat EU-land.
- Tredje part i form av EasyJacket anlitade databehandlare såsom IT-leverantörer. Databehandlare kan finns i Sverige, EU och utanför EU.
- Tredje part i form av betalningsförmedlare t.ex. banker, betal- och kreditkortsföretag, BankID, Swish och liknanden i syfte att erhålla betalning för EasyJackets tjänster och att säkert identifiera Användaren. Dessa mottagare finns i Sverige och EU samt i undantagsfall utanför EU.

## 8. Tillhandahållande av personuppgifter i förhållande till tredje part

Vid nyttjande av Hemsidan samlar EasyJacket in personuppgifter som är nödvändiga för att utställa en personlig biljett. Därtill samlas personuppgifter in i syfte att tillgodose det behov som tredje part kan ha i förhållande till våra Användare. Se punkt 3 om ändamålen med behandlingen. Dessa personuppgifter utgörs av de uppgifter som nämns under punkt 4 “ Personuppgifter som är föremål för hantering ”.Tredje part får genom avtal med EasyJacket nyttja den information som EasyJacket genom Hemsidan upptar i förhållande till Användaren.

EasyJacket värnar om er integritet och vid överföring av informationen till tredje part säkerställs att inga andra än de relevanta parterna har tillgång till den information som överförs. Om ni inte samtycker till denna hantering av era uppgifter hänvisar vi till punkt 11 “ Våra Skyldigheter ”, varpå ni kan begära radering eller begränsning av vissa eller alla personuppgifter som EasyJacket hanterar avseende era personuppgifter.

EasyJacket säkerställer att tredje part som genom avtal om överföring får tillgång till uppgifterna har uppställt en integritetspolicy enligt relevant lagstiftning. EasyJacket lämnar således inte ut information om hanteringen av denna inte kan säkerställas. Relevant lagstiftning utgörs av hantering i enlighet med Dataskyddsförordningen (GDPR).

## 9. Lagring samt säkerhet för personuppgifter

Vid insamlingen av personuppgifter genomförs en digital överföring av informationen till EasyJackets centrala datalagring. EasyJacket försäkrar härmed att den lagring som vidtas uppfyller den lagtekniska standard som krävs för att lagring av personuppgifter skall få ske. Således kan ni som Användare försäkra er om att de uppgifter som lämnas med hjälp av mobilt BankID skyddas på ett ändamålsenligt vis. Med lagteknisk standard avses Dataskyddsförordningen (GDPR).

Lagring av era personuppgifter sker under tiden för ert användande av Hemsidan. Lagring hos EasyJacket sker högst fem (5) år efter er senaste användning av Hemsidan.

Personuppgifter sparas dock alltid så länge det föreligger en laglig skyldighet att spara in-formationen: T.ex. ska bokföringsmaterial sparas så länge gällande bokföringsregler kräver (f.n. 7 år).

EasyJackets skyldighet att radera era uppgifter enligt punkt 11 “Våra Skyldigheter ” uppfylls genom att ni till EasyJacket meddelar att ni inte längre medger att era personuppgifter lagras i EasyJackets datalagringscentral. Radering sker då av alla uppgifter, dock med undantag för uppgifter som EasyJacket har en laglig skyldighet att spara under en längre tidperiod.

## 10. Våra skyldigheter

EasyJacket har i förhållande till er som Användare skyldighet att medge:

- Rätt till tillgång - rätten att begära information om vilken behandling som görs samt tillgång till de personuppgifter om Användaren som EasyJacket behandlar .
- Rätt till rättelse - rätten att begära att EasyJacket rättar eller uppdaterar era personuppgifter om de är felaktiga eller ofullständiga.
- Rätt till radering - rätten att begära att EasyJacket raderar era personuppgifter i den utsträckning som gällande lagregler medger.
- Rätt till begränsning - rätten att begära att EasyJacket tillfälligt eller permanent upphör med behandlingen av alla eller vissa av era personuppgifter i den utsträckning som gällande regler medger
- Rätt att i den utsträckning som gällande regler medger invända;
  - rätten att, när som helst, invända mot att EasyJacket behandlar era personuppgifter för ett visst ändamål.
  - rätten att invända mot att era personuppgifter behandlas för direktmarknadsföring.
- Rätt till dataportabilitet - rätten att begära en kopia av era personuppgifter i elektroniskt format för att överföra dessa personuppgifter i syfte att användas i en annan parts tjänster enligt gällande regler, samt
- Rätt att inte bli föremål för automatiserat beslutsfattande enligt gällande regler - rätten att inte bli föremål för några beslut som grundas enbart på automatiserat beslutsfattande, inklusive profilering, där beslut skulle medföra rättsliga följder för er som person eller någon annan påverkan av liknande karaktär.

Önskar ni som Användare av våra tjänster göra anspråk på en eller flera av dessa rättigheter meddelas detta direkt till vår personuppgiftsansvarige, vilken kontaktas på:

- Mail: <Adam@EasyJacket.se>

Meddelande om anspråk på rättighet kontrolleras löpande och svar avges utan dröjsmål i syfte att tillgodose ert behov av en smidig tjänst och hantering av ert ärende. Skulle ni uppleva att svaret inte tillgodoser ert behov har ni alltid möjligheten att kontakta eller framföra klagomål till Integritetsskyddsmyndigheten (Den svenska myndigheten för dataskydd).

Efter begäran lämnas information om personuppgifter som avser Användaren i separat dokument. Sådan information

- lämnas elektroniskt via email om Användarens identitet kan säkerställas, eller
- skickas till Användarens folkbokföringsadress med ordinarie postgång.

## 10. Kontaktinformation

Personuppgiftsansvarig: EasyJacket AB, org. nr. 559232-8081, C/o Venner Group AB, Skeppsbron 5, 411 21 Göteborg

Kontaktperson:

Adam Andersson

<Adam@EasyJacket.se>

EasyJacket AB

## 11. Rätten att inge klagomål till en tillsynsmyndighet

Användare har rätt att inge klagomål avseende EasyJackets behandling av personuppgifter till tillsynsmyndighet.

I Sverige är tillsynsmyndigheten Integritetsskyddsmyndigheten, Box 8114, 104 20 Stockholm, <www.imy.se>.`;

  return (
    <div className="absolute top-0 left-0 bottom-0 right-0 overflow-auto">
      <div style={{ padding: 4, backgroundColor: 'white', paddingTop: 4 }}>
        <ReactMarkdown remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>
        <ScrollButton />
      </div>
    </div>
  );
};
