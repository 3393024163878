import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { isValid, parse } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useRef } from 'react';
import { UserGenderEnum } from '../generated';
import { useUserForms } from '../hooks/use-user-forms';

export const CompleteUserProfile = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useUserForms();

  const monthRef = useRef(null);
  const dayRef = useRef(null);

  const validationSchema = yup.object().shape({
    year: yup
      .number()
      .integer()
      .max(4)
      .min(1900, t('completeprofile.date.error2'))
      .max(2021, t('completeprofile.date.error2'))
      .required(t('completeprofile.date.error1')),
    month: yup
      .number()
      .min(1, t('completeprofile.date.error2'))
      .max(12, t('completeprofile.date.error2'))
      .required(t('completeprofile.date.error1')),
    day: yup
      .number()
      .min(1, t('completeprofile.date.error2'))
      .max(31, t('completeprofile.date.error2'))
      .required(t('completeprofile.date.error1'))
      .test('day', t('completeprofile.date.error2'), function () {
        function isValidDate(year: number, month: number, day: number) {
          const parsed = parse(`${day}/${month}/${year}`, 'P', new Date(), {
            locale: enGB,
          });

          return isValid(parsed);
        }
        return isValidDate(this.parent.year, this.parent.month, this.parent.day);
      }),
    gender: yup.string().oneOf([UserGenderEnum.Female, UserGenderEnum.Male, UserGenderEnum.Other]),
  });

  const formik = useFormik({
    initialValues: {
      gender: UserGenderEnum.Other,
      year: '',
      month: '',
      day: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const padZero = (str: string) => {
        const num = parseInt(str, 10);
        return num < 10 ? `0${num}` : str;
      };
      const birthdate = `${values.year}-${padZero(values.month)}-${padZero(values.day)}`;
      handleSubmit({
        dto: {
          birthdate,
          gender: values.gender,
        },
      });
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAutoFocusChange = (e: any, nextRef: any) => {
    const { name, value } = e.target;

    if (name === 'year') {
      if (value.length > 4) {
        return;
      }
      formik.handleChange(e); // call the original formik handleChange
      if (value.length === 4) {
        nextRef.current.focus(); // Move focus to the next ref
      }
    } else if (name === 'month') {
      if (value.length > 2) {
        return;
      }
      formik.handleChange(e); // call the original formik handleChange
      if (value.length === 2) {
        nextRef.current.focus(); // Move focus to the next ref
      }
    }
  };

  return (
    <div className="p-4 flex flex-col justify-between h-full">
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Typography variant="h6" sx={{ color: 'rgba(255,255,255,1)' }}>
            {t('completeprofile.header')}
          </Typography>
          <br />
          <div className="flex gap-2">
            <TextField
              className="flex-[4_4_0%]"
              autoFocus
              id="year"
              name="year"
              label={t('completeprofile.year')}
              type="number"
              size="small"
              placeholder="ÅÅÅÅ"
              value={formik.values.year}
              inputProps={{
                inputMode: 'decimal',
              }}
              onChange={(e) => handleAutoFocusChange(e, monthRef)}
              onBlur={formik.handleBlur}
              error={formik.touched.year && Boolean(formik.errors.year)}
              helperText={formik.touched.year && formik.errors.year ? formik.errors.year : ' '}
            />
            <TextField
              className="flex-[3_3_0%]"
              inputRef={monthRef}
              id="month"
              name="month"
              label={t('completeprofile.month')}
              type="number"
              size="small"
              placeholder="MM"
              inputProps={{
                inputMode: 'decimal',
              }}
              value={formik.values.month}
              onChange={(e) => handleAutoFocusChange(e, dayRef)}
              onBlur={formik.handleBlur}
              error={formik.touched.month && Boolean(formik.errors.month)}
              helperText={formik.touched.month && formik.errors.month ? formik.errors.month : ' '}
            />
            <TextField
              className="flex-[3_3_0%]"
              inputRef={dayRef}
              id="day"
              name="day"
              size="small"
              inputProps={{
                inputMode: 'decimal',
              }}
              label={t('completeprofile.day')}
              type="number"
              placeholder="DD"
              value={formik.values.day}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(e: any) => {
                if (e.target.value.length > 2) return;
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.day && Boolean(formik.errors.day)}
              helperText={formik.touched.day && formik.errors.day ? formik.errors.day : ' '}
            />
          </div>
        </div>

        <br />

        <div className="m-auto w-max">
          <FormControl
            sx={{ mt: -1 }}
            component="fieldset"
            error={formik.touched.gender && Boolean(formik.errors.gender)}
          >
            <RadioGroup
              sx={{ mb: -2 }}
              row
              aria-label="gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                sx={{ color: 'white' }}
                value="MALE"
                control={<Radio sx={{ color: 'white' }} />}
                label={t('completeprofile.male')}
              />
              <FormControlLabel
                sx={{ color: 'white' }}
                value="FEMALE"
                control={<Radio sx={{ color: 'white' }} />}
                label={t('completeprofile.female')}
              />
              <FormControlLabel
                sx={{ color: 'white' }}
                value="OTHER"
                control={<Radio sx={{ color: 'white' }} />}
                label={t('completeprofile.other')}
              />
            </RadioGroup>
            {formik.touched.gender && Boolean(formik.errors.gender) ? (
              <FormHelperText>{t('completeprofile.gender.error')}</FormHelperText>
            ) : (
              <FormHelperText> </FormHelperText>
            )}
          </FormControl>
        </div>
        <br />

        <div>
          <button
            type="submit"
            className="rounded-full w-full bg-white px-4 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            {t('completeprofile.button.save')}
          </button>
        </div>
      </form>
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">{t('completeprofile.subheader')}</p>

            <p className="mt-3 text-sm md:ml-6 md:mt-0">
              <a
                href="/policy"
                target="_blank"
                className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
              >
                {t('completeprofile.link.privacy')}
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
