import { UpdateUserDto } from '../../generated';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { api } from '../client';
import { useAuth } from '../../contexts/auth-context';

export const useUpdateCurrentUser = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const { user: currentUser } = useAuth();
  const client = useQueryClient();
  const { isLoading, data, mutate } = useMutation(
    ['update-user'],
    (dto: Partial<UpdateUserDto>) =>
      api.usersControllerUpdate({
        id: currentUser?.user?.id ?? '',
        updateUserDto: { ...dto },
      }),
    {
      onSuccess: () => {
        client.invalidateQueries(['currentUser']);
        onSuccess && onSuccess();
      },
    },
  );

  return { updateCurrentUser: mutate, isLoading, data };
};
