import ErrorIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SessionResponseError } from '../generated';
import { Spinner } from './spinner';

interface Props {
  data?: SessionResponseError;
  onClose: () => void;
  loading: boolean;
}

export const BankIdBanner = ({ data, onClose, loading }: Props) => {
  const getMessage = () => {
    if (!data) return '';
    if (data.grandidObject?.message?.status === 'pending') {
      return 'Starta BankID-appen.';
    }
    if (data.grandidObject?.message?.status === 'failed') {
      return 'Åtgärden avbruten.';
    }
    return JSON.stringify(data, null, 2);
  };

  if (!data && !loading) return null;
  return (
    <React.Fragment>
      <Container maxWidth={'xs'}>
        <Box>
          <Typography>Logga in med mobilt BankID</Typography>
        </Box>
        <Divider sx={{ pt: 1 }} />
        <Grid
          container
          alignItems="center"
          sx={{
            pt: 2,
          }}
        >
          <Grid item>
            <div className="flex justify-center">
              {loading && !data ? (
                <Spinner />
              ) : (
                <ErrorIcon fontSize="large" sx={{ color: 'red' }} />
              )}
            </div>
          </Grid>
          <Grid item xs={9}>
            <Typography>{getMessage()}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ justifyContent: 'center', pt: 2 }}>
          <Grid item xs={10}>
            <Button color="primary" fullWidth sx={{ fontSize: 16 }} onClick={onClose}>
              Avbryt
            </Button>
          </Grid>
        </Grid>
      </Container>
      <CssBaseline />
    </React.Fragment>
  );
};
