import { useQuery } from '@tanstack/react-query';
import { api } from '../api';
import { RouteNavigation } from '../components/ui/navigations';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { cn } from '../utils/css';
import { PaymentWithFacilityStatusEnum } from '../generated';
import { Spinner } from '../components/spinner';
import { ArrowRightEndOnRectangleIcon, ReceiptRefundIcon } from '@heroicons/react/24/outline';

export const ReceiptsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: receipts } = useQuery({
    queryKey: ['receipts'],
    queryFn: async () => {
      const { data } = await api.paymentsControllerFindPaymentsForUser();
      return data;
    },
  });

  const priceStyle = (status: PaymentWithFacilityStatusEnum) => {
    switch (status) {
      case 'COMPLETED':
        return 'text-emerald-400 ';
      case 'PENDING':
        return 'text-zinc-400';
      case 'FAILED':
        return 'text-rose-500 line-through';
      case 'REFUNDED':
        return 'text-sky-500';
      default:
        return '';
    }
  };

  return (
    <>
      <section className="size-full overflow-y-scroll relative">
        <RouteNavigation sticky label={'receipts.header'} route="/dashboard" />
        <Table className="p-2" striped>
          <TableHead>
            <TableRow>
              <TableHeader>{t('general.facility')}</TableHeader>
              <TableHeader>{t('general.date')}</TableHeader>
              <TableHeader>{t('general.price')}</TableHeader>
              <TableHeader>{}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts?.map((receipt, index) => {
              const formattedDate = format(new Date(receipt.createdAt), 'EE,  Mo MMM');
              const status = receipt.status;
              return (
                <TableRow
                  key={receipt.id + '-' + index}
                  onClick={() => navigate(`/receipts/${receipt.id}`)}
                >
                  <TableCell>
                    <p className="max-w-24 truncate">{receipt.facility.name}</p>
                  </TableCell>
                  <TableCell>{formattedDate}</TableCell>
                  <TableCell className={cn('gap-2 flex items-center', priceStyle(status))}>
                    {status === 'PENDING' && <Spinner size="small" />}
                    {status === 'REFUNDED' && <ReceiptRefundIcon className="size-4 text-sky-400" />}
                    {receipt.amount / 100} {receipt.currency}
                  </TableCell>
                  <TableCell>
                    <ArrowRightEndOnRectangleIcon className="size-4" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </section>
    </>
  );
};
