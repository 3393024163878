import { Dialog, DialogBackdrop } from '@headlessui/react';

import { PhoneNumberFormV2 } from '../components/forms/phone-number-form-v2';
import { VerificationCodeForm } from '../components/forms/verification-code-form';
import { NewLogo } from '../components/logo';
import { Spinner } from '../components/spinner';
import { usePhoneAuth } from '../hooks/use-phone-auth';

export const PhoneLoginView = () => {
  const { handleInit, handleSignIn, showCodeInput, handleRetry, isLoading } = usePhoneAuth();

  return (
    <>
      <div className="w-full h-full  flex justify-evenly flex-col">
        <NewLogo />
        <div className="px-[10%]">
          {!showCodeInput && <PhoneNumberFormV2 onSubmit={handleInit} />}
          {showCodeInput && <VerificationCodeForm onSubmit={handleSignIn} onRetry={handleRetry} />}
        </div>
        <div />
      </div>
      <Dialog open={isLoading} onClose={() => {}} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-bgPrimary bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Spinner />
          </div>
        </div>
      </Dialog>
    </>
  );
};
