import PhoneIcon from '@mui/icons-material/Phone';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CountryCode, E164Number } from 'libphonenumber-js';
import { FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input';
import './input.css';

interface PhoneNumberFormProps {
  onSubmit: (phone: string) => void;
}

const defaultCountryCode = 'SE';
// const defaultCountryCode = navigator.language.split('-')[1] || 'SE';

export const PhoneNumberFormV2 = ({ onSubmit }: PhoneNumberFormProps) => {
  const [value, setValue] = useState<E164Number>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChange = (value?: E164Number) => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber && parsedNumber.isValid()) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
    setValue(value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (value && isValid) {
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ height: '100%' }}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}
      >
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <PhoneIcon sx={{ color: 'white', marginRight: 1 }} />
          <Typography variant="h5">{t('phone.header')}</Typography>
        </Box>

        <section
          style={{
            marginTop: 16,
            display: 'flex',
            alignItems: 'start',
            gap: 8,
          }}
        >
          <PhoneInput
            numberInputProps={{
              style: {
                backgroundColor: 'transparent',
              },
            }}
            style={{ backgroundColor: 'transparent' }}
            defaultCountry={defaultCountryCode as CountryCode}
            international
            class={`PhoneInputInput ${isValid ? '' : 'error'}`}
            className="custom-phone-input"
            onChange={handleChange}
            countrySelectComponent={CountrySelect}
            value={value}
            autoFocus
          />
        </section>
        <button
          type="submit"
          className="rounded-full bg-white px-4 py-2.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full"
        >
          {t('phone.continue')}
        </button>
      </Box>
    </form>
  );
};

interface CountryProps {
  options: {
    value?: string;
    label: string;
  }[];
  value?: string;
  onChange: (value: string) => void;
}

const preferredCountries = ['SE', 'NO', 'DK', 'FI', 'DE', 'US', 'GB', 'CH', 'CN', 'ES', 'FR'];

const CountrySelect = (props: CountryProps) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ m: 1, minWidth: 80 }}>
      <InputLabel id="demo-simple-select-label">{t('phone.country')}</InputLabel>
      <Select
        style={{ backgroundColor: 'transparent' }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props.value}
        label={t('phone.country')}
        onChange={(e) => props.onChange(e.target.value as string)}
        renderValue={(value) => (
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${value.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${value.toLowerCase()}.png`}
            alt=""
          />
        )}
      >
        {props.options
          .sort((a, b) => {
            if (
              preferredCountries.includes(a.value || '') &&
              preferredCountries.includes(b.value || '')
            ) {
              const aIndex = preferredCountries.indexOf(a.value || '');
              const bIndex = preferredCountries.indexOf(b.value || '');

              return aIndex - bIndex;
            }

            if (preferredCountries.includes(a.value || '')) {
              return -1;
            }
            if (preferredCountries.includes(b.value || '')) {
              return 1;
            }
            return 0;
          })
          .map((option) => (
            <MenuItem key={option.label} value={option.value}>
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.value?.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.value?.toLowerCase()}.png`}
                alt=""
                style={{ marginRight: 8 }}
              />
              {option.label}{' '}
              {option.value && `(+${getCountryCallingCode(option.value as CountryCode)})`}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
