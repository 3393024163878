import { useState, useEffect } from 'react';

export const useUserConsent = () => {
  const [consent, setConsent] = useState<boolean | null>(null);

  useEffect(() => {
    const savedConsent = localStorage.getItem('userConsent');
    if (savedConsent !== null) {
      setConsent(JSON.parse(savedConsent));
    }
  }, []);

  const updateConsent = (userConsent: boolean) => {
    localStorage.setItem('userConsent', JSON.stringify(userConsent));
    setConsent(userConsent);
  };

  return { consent, updateConsent };
};
