import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import { QRCode as CheckedOutQRCode } from '../generated';

interface Props {
  qrCode: CheckedOutQRCode;
}

const animation = {
  initial: { y: 7, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

export const CheckedOutTicket = ({ qrCode }: Props) => {
  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          height: '100%',
          width: '100%',
          gap: 20,
        }}
      >
        <span className="text-4xl text-white text-center">Visa upp för garderoben</span>
        <motion.div
          style={{
            padding: 8,
          }}
          className="flex flex-col items-center justify-center gap-4"
          initial="initial"
          animate="animate"
          variants={animation}
        >
          {<QRCode value={JSON.stringify(qrCode)} size={255} />}
        </motion.div>
      </section>
    </>
  );
};
