import { motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import { PaidQRCode } from '../generated';
import TicketBackground from '../assets/ticket-paid.svg';
import { useTranslation } from 'react-i18next';

import TicketTop from '../assets/torn_top.svg';
import TicketBottom from '../assets/torn_bottom.svg';

interface Props {
  qrCode: PaidQRCode;
}

const animation = {
  initial: { y: 7, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

export const PaidTicket = ({ qrCode }: Props) => {
  const { t } = useTranslation();

  if (qrCode.entranceConsumedAt)
    return (
      <motion.div
        key="consumed-ticket"
        initial="initial"
        animate="animate"
        variants={animation}
        className="relative p-1 flex justify-center items-center"
      >
        <div className="flex flex-col gap-2 pointer-events-none">
          <div className="relative w-full h-full">
            <img src={TicketTop} className="w-full h-full " />
            <div className="inset-0 absolute flex flex-col gap-2">
              <div className="relative py-1 bg-gray-800  mt-2">
                <p className="text-center text-white uppercase font-medium text-sm">
                  {t('tickets.consumed-at')} {qrCode.paymentFacilityName}
                </p>
              </div>
              <QRCode value={JSON.stringify(qrCode)} size={230} className="mx-auto" includeMargin />
            </div>
          </div>

          <div className="-rotate-1 relative flex flex-col">
            <img src={TicketBottom} />
            <div className="absolute inset-0 flex flex-col ">
              <p className="text-black uppercase text-xl font-medium mx-4 text-center flex-1 items-center flex">
                {t('tickets.show-qr')}
              </p>
              <div className="flex w-full items-center justify-center gap-2 pb-2">
                <p className="text-bgPrimary/70 text-base text-center">
                  {new Date(qrCode.entranceConsumedAt).toLocaleDateString()}
                </p>
                <p className="text-bgPrimary/70 text-base text-center font-semibold">
                  {new Date(qrCode.entranceConsumedAt).toLocaleTimeString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );

  return (
    <motion.div
      key="paid-ticket"
      initial="initial"
      animate="animate"
      variants={animation}
      className="relative p-6 flex justify-center items-center"
    >
      <img src={TicketBackground} alt="Ticket Background" className="absolute z-10 w-full h-auto" />
      <div className="relative z-20 space-y-4">
        <div className="relative flex flex-row items-center justify-center px-4 py-2 bg-gray-800 rounded-md">
          <div className="absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-r from-green-500 to-green-700" />
          <p className="text-center text-gray-900 uppercase font-medium relative">
            {t('tickets.paid-at')} {qrCode.paymentFacilityName}
          </p>
        </div>
        <QRCode value={JSON.stringify(qrCode)} size={255} />
        <div className="flex flex-row justify-center">
          <p className="text-center text-gray-800 text-2xl font-medium uppercase tracking-wider max-w-xs">
            {qrCode.entranceActive ? t('tickets.show-qr-entrance') : t('tickets.show-qr')}
          </p>
        </div>
      </div>
    </motion.div>
  );
};
