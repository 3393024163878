import { useMutation } from '@tanstack/react-query';
import { api } from '../client';

export const usePhoneInit = () => {
  const { isLoading, data, mutate } = useMutation(
    ['phone-init'],
    (phone: string) =>
      api.authControllerInitUserPhoneSignIn({
        initUserPhoneSignInDto: {
          phone,
        },
      }),
    {},
  );

  return { data: data?.data, isLoading, mutate };
};
