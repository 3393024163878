import { useQuery } from '@tanstack/react-query';
import { api } from '../api';

export const useAuth = () => {
  const query = useQuery(['auth'], async () => {
    return api.usersControllerFindMe();
  });

  const authenticated = !!query.data?.data?.user;
  const user = query.data?.data?.user;

  return {
    ...query,
    authenticated,
    user,
  };
};
