import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface VerificationCodeFormProps {
  onSubmit: (verificationCode: string) => void;
  onRetry: () => void;
}

export function VerificationCodeForm({ onSubmit, onRetry }: VerificationCodeFormProps) {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    verificationCode: yup
      .string()
      .matches(/^[0-9]{6}$/, t('verification.error'))
      .required(t('verification.error')),
  });

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values.verificationCode);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} style={{ height: '100%' }}>
      <Box
        sx={{
          flexDirection: 'column',
          height: '100%',
          display: 'flex',
        }}
      >
        <Typography variant="h6" sx={{ color: 'rgba(255,255,255,1)' }}>
          {t('verification.header')}
        </Typography>
        <Typography variant="h6" sx={{ mt: 0, color: 'rgba(255,255,255,0.8)' }}>
          {t('verification.subheader')}
        </Typography>

        <TextField
          sx={{
            '& .MuiFormLabel-root': {
              color: 'rgba(255,255,255,1)',
            },
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'rgba(255,255,255,0.6)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255,255,255,0.6)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#1DA2DC',
              },
            },
          }}
          fullWidth
          id="verificationCode"
          name="verificationCode"
          label={t('verification.label')}
          margin="normal"
          type="tel"
          autoFocus
          value={formik.values.verificationCode}
          onChange={formik.handleChange}
          error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
          helperText={formik.touched.verificationCode && formik.errors.verificationCode}
        />

        <Typography sx={{ color: 'rgba(255,255,255,1)', cursor: 'pointer' }}>
          {t('verification.body1')}
          <Link onClick={onRetry}>{t('verification.body2')}</Link>
        </Typography>

        <br />

        <button
          type="submit"
          className="rounded-full bg-white px-4 py-2.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full"
        >
          {t('verification.button')}
        </button>
      </Box>
    </form>
  );
}
