import QRCode from 'qrcode.react';
import { getContrastColor, getZoneNameColor } from '../utils/colors';
import { motion } from 'framer-motion';
import TicketBackground from '../assets/ticket-svg-checkedin.svg';
import { useTranslation } from 'react-i18next';
import { CheckedInQrCode } from '../generated';

interface Props {
  qrCode: CheckedInQrCode;
}

const animation = {
  initial: { y: 7, opacity: 0 },
  animate: { y: 0, opacity: 1 },
};

export const CheckedInTicket = ({ qrCode }: Props) => {
  const zoneColor = getZoneNameColor(qrCode.zoneName, qrCode.customZoneColors, qrCode.zoneColor);

  const { t } = useTranslation();

  return (
    <motion.div
      className="relative p-20 flex justify-center items-center overflow-hidden"
      initial="initial"
      animate="animate"
      variants={animation}
    >
      <img src={TicketBackground} alt="Ticket Background" className="absolute z-10 w-full h-auto" />
      <div className="relative z-20 space-y-4">
        <div>
          <p className="text-center text-gray-800 select-none">{t('tickets.checked-in-at')}</p>
          <p className="text-center text-gray-800 text-2xl font-medium uppercase select-none tracking-wider">
            {qrCode.facilityName}
          </p>
        </div>
        <QRCode value={JSON.stringify(qrCode)} size={255} />
        <div
          className={`flex flex-row items-center justify-between px-4 py-2 rounded-md`}
          style={{
            backgroundColor: zoneColor,
            color: getContrastColor(zoneColor),
          }}
        >
          <p className="font-medium text-gray-800 select-none">{qrCode.zoneName}</p>
          <p className="font-medium text-gray-800 select-none">{qrCode.slotNumber}</p>
        </div>
      </div>
    </motion.div>
  );
};
